import React from "react"
import { graphql } from "gatsby"

import Layout from "../layout"
import SEO from "../seo"
import { Container, Row, Col } from "react-bootstrap"

import '../../scss/pages/page.scss'

const urlHelper = require('../../helpers/url')

const PageCTAs = ({lang, ctas}) => ctas.map((cta) => {
  const contactUrl = urlHelper(lang, 'contact') + "?pageIntent=" + cta.intent

  return (
    <div key={cta.caption.text}>
      <span dangerouslySetInnerHTML={{ __html: cta.introduction.html }} />
      <a className="gls-button" href={contactUrl}>{cta.caption.text}</a>
    </div>
  )
})

const Page = ({ data: { prismicPage } }) => {
  const { lang, data } = prismicPage
  return (
    <Layout lang={lang}>
      <SEO title={data.title.text} keywords={[`lasfera`, `gallery`, `galleria`]} />
      <div className="page-heading">
        <Container>
          <h1 className="gls-title">{data.title.text}</h1>
          <div className="gls-subtitle" dangerouslySetInnerHTML={{ __html: data.subtitle.text }} />
        </Container>
      </div>
      <Container>
        <Row>
          <Col>
            <h2 className="secondary-title">{data.secondary_title.text}</h2>
            <div className="page-content" dangerouslySetInnerHTML={{ __html: data.content.html }} />
          </Col>
        </Row>
      </Container>
      { data.optional_content.html && data.optional_content.html !== '<p></p>' && <div className="page-optional-content">
        <Container>
          <div className="text-center" dangerouslySetInnerHTML={{ __html: data.optional_content.html }} />
        </Container>
      </div> }
      { data.calls_to_action.length > 0 && <div className="page-optional-content">
        <Container>
          <div className="text-center">
            <PageCTAs lang={lang} ctas={data.calls_to_action} />
          </div>
        </Container>
      </div> }
    </Layout>
  )
}

export default Page

export const pageQuery = graphql`
  query PostBySlug($uid: String!, $lang: String!) {
    prismicPage(uid: {eq: $uid}, lang: {eq: $lang}) {
      uid
      lang
      data {
        title {
          text
        }
        subtitle {
          text
        }
        secondary_title {
          text
        }
        content {
          html
        }
        optional_content {
          html
        }
        calls_to_action {
          introduction {
            html
          }
          caption {
            text
          }
          intent
        }
      }
    }
  }
`
